/**
 * Validates street object
 *
 * @param {object} opts
 * @param {object} opts.streetObj
 * @param {*} opts.defaultValue
 * @returns {boolean|*}
 */
const isValidStreetObj = opts => {
  if (
    !opts ||
    !opts.streetObj ||
    typeof opts.streetObj !== 'object' ||
    !opts.streetObj.street_name ||
    !opts.streetObj.street_number
  ) {
    return opts?.defaultValue ?? false;
  }

  const safeName = /^[A-Za-z0-9\s.\-_@#]{1,350}$/;

  // Validate street_name
  if (
    typeof opts.streetObj.street_name !== 'string' ||
    opts.streetObj.street_name.length > 100 ||
    safeName.exec(opts.streetObj.street_name) == null
  ) {
    return false;
  }

  // Validate street_type
  if (
    opts.streetObj.street_type &&
    (typeof opts.streetObj.street_type !== 'string' ||
      opts.streetObj.street_type.length > 20 ||
      safeName.exec(opts.streetObj.street_type) == null)
  ) {
    return false;
  }

  // Validate street_affix
  if (
    opts.streetObj.street_affix &&
    (typeof opts.streetObj.street_affix !== 'string' ||
      opts.streetObj.street_affix.length > 50 ||
      safeName.exec(opts.streetObj.street_affix) == null)
  ) {
    return false;
  }

  // Validate street_number
  if (
    typeof opts.streetObj.street_number !== 'number' &&
    !/^(\d+[A-Z]?)(-\d+[A-Z]?)*$/.test(opts.streetObj.street_number)
  ) {
    return false;
  }

  return true;
};

export default isValidStreetObj;
