<template>
  <div :class="seoPagesClass">
    <wl-header
      id="app"
      data-information-page
      :logo="header.logo"
      :logo-standard-size="logoSize"
      :logo-small="header.logo_small"
      :logo-small-size="logoSmallSize"
      :logo-name="header.name"
      :logo-title="header.logo_title"
      :logo-path="logoPath"
      :menu="
        showFullToggleMenu() ? [] : header.menu.slice(0, menuLinksDesktopCount)
      "
      :path="path"
      :hide-menu="isLanding"
      :show-aux-menu="!showFullToggleMenu() && !isLanding"
      :is-fixed="isFixed"
      :class="{
        'wl-header--mobile-search': mobileSearchActive,
        'wl-header--home': isHome,
        'wl-header--search-results': isSearchResults,
        'wl-header--seo': isSeoSearch,
        'wl-header--letter': isLetterPage,
        'wl-header--landing-alt': isLandingResultsAlt
      }"
    >
      <template #header-bar v-if="!isLanding">
        <button
          type="button"
          id="searchToggle"
          class="ic-search-toggle"
          aria-controls="searchBar"
          aria-expanded="false"
          aria-label="Toggle Search"
          v-touch:tap.prevent="mobileSearchOpen"
        >
          <span class="ic-search-toggle__text" v-text="mobileSearchText" />
          <div class="ic-search-toggle__icon">
            <img
              :src="header.mobile_search_icon"
              alt="Search Icon"
              loading="lazy"
              width="15px"
              height="15px"
              class="ic-search-toggle__icon-image"
            />
          </div>
        </button>
        <tz-search
          v-if="showDesktopSearchBar()"
          :in-header="true"
          :prefill-fields="prefillFields"
          :fallback-fields="fallbackFields"
          :phone-link="phoneURL"
          :people-link="peopleURL"
          :search-icon="header.mobile_search_icon"
          :no-query-string="true"
          :default-state-label="header.default_state_option_label"
          @tz-search-override-redirect="navigateToSeo"
        />
        <button
          type="button"
          id="searchClose"
          class="ic-search-cancel"
          v-touch:tap.prevent="mobileSearchClose"
          v-text="`Cancel`"
        />
        <ul id="toggle-menu" class="wl-header__toggle-menu">
          <li
            class="wl-header__toggle-menu-item"
            :key="link.label"
            v-for="link in showFullToggleMenu()
              ? header.menu
              : header.menu.slice(menuLinksDesktopCount)"
          >
            <a
              class="wl-header__toggle-menu-link"
              :class="{
                'wl-header__toggle-menu-link--active': path === link.url
              }"
              :href="link.url"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              v-text="link.label"
            />
          </li>
        </ul>
      </template>
      <template #header-bar v-else-if="isLanding">
        <wl-trust-badges />
      </template>
    </wl-header>
    <main
      class="main"
      :class="{
        'main--password': isPasswordPage,
        'main--landing-alt': isLandingResultsAlt
      }"
      ref="insideContent"
    >
      <nuxt ref="page" :prefill-fields="prefillFields" />
      <tz-footer
        :class="{
          'tz-footer--landing': isLanding
        }"
        alpha-base-link="/names-by-letter/"
        :display-sections="
          isLanding
            ? ['globalLinks', 'disclaimer']
            : [
                'alphaSelect',
                'contactInfo',
                'columnSecondary',
                'columnTertiary',
                'columnQuarternary',
                'disclaimer',
                'globalLinks'
              ]
        "
        :footer="
          isLanding ? { ...footer, disclaimer: landingDisclaimer } : footer
        "
        :headerFixed="isFixed"
        :page="page"
        :has-trailing-slash="true"
        :is-alpha-lower="true"
      />
    </main>
    <div class="ic-scrim" v-touch:tap.self.stop="closeHeaderModal">
      <wl-modal-form
        :class="{
          'wl-modal-form--seo-phone': isSeoPhone,
          'wl-modal-form--seo-address': isSeoAddress
        }"
        :open="true"
        :modal-title="getModalTitle()"
        :modal-text="getModalText()"
        :inputs="inputs"
        :confirm-button-text="header.modal.confirm_button_text"
        :cancel-button-text="header.modal.cancel_button_text"
        :close-modal="closeHeaderModal"
        @wl-modal-input-focus="removeInputError"
        @wl-modal-form-change="resetNameInputErrors"
        @wl-modal-form-confirm="navigateToPeopleFlow"
        @wl-modal-form-cancel="closeHeaderModal"
      />
    </div>
  </div>
</template>

<script>
// Components
import WlHeader from '@/components/shared/wl-header';
import TzFooter from '@trazi/tz-footer/src/tz-footer.vue';
import TzSearch from '@trazi/tz-search/src/tz-search.vue';
import WlModalForm from '@/components/shared/wl-modal-form';
import WlTrustBadges from '@/components/shared/wl-trust-badges.vue';
// Helpers
import { unregisterAndClearCaches } from '@/assets/js/shared/service-worker';
import resultHelper from '@/assets/js/resultHelper';
import getSessionId from '@/assets/js/helpers/getSessionId';
import getMobileQuery from '@/assets/js/shared/helpers/getMobileQuery';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getStateAbbrFromName from '@/assets/js/shared/helpers/getStateAbbrFromName';
import formatCity from '@/assets/js/shared/helpers/formatCity';
import mediaQueryListener from '@/assets/js/shared/helpers/mediaQueryListener';
import formatNameCaseWithSplitterAndSpacer from '@/assets/js/shared/helpers/formatNameCaseWithSplitterAndSpacer';
import getStateFromAbbr from '@/assets/js/shared/helpers/getStateFromAbbr';
import dashedPhoneNumber from '@/assets/js/shared/helpers/dashedPhoneNumber';
import formatPhoneNumber from '@/assets/js/shared/helpers/formatPhoneNumber';
import getStatesForDropdown from '@/assets/js/shared/helpers/getStatesForDropdown';
import parseParams from '@/assets/js/shared/helpers/parseParams';
import userLocation from '@/mixins/shared/userLocation';
const mobileMediaQuery = getMobileQuery({ width: 992 });
// CMS
const footerCMS = require(`@/assets/cms/components/footer.json`);
// TODO: Update to use getLastXDays method from the js submodule once its been added
let insertionIndex = 1;
if (footerCMS.quarternary_list.length < 12) {
  for (let i = 1; i < 10; i++) {
    const today = new Date();
    const priorDate = new Date(
      new Date().setDate(today.getDate() - i)
    ).toLocaleDateString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }); // returns MM/DD/YYYY
    const mm = priorDate.split('/')[0];
    const m = priorDate.split('/')[0].replace(/^0/, '');
    const dd = priorDate.split('/')[1];
    const d = priorDate.split('/')[1].replace(/^0/, '');
    const yyy = priorDate.split('/')[2];

    const dateEntry = {
      label: `${mm}/${dd}`,
      url: `${footerCMS.quarternary_list[0].url}${yyy}-${m}-${d}/`
    };

    footerCMS.quarternary_list.splice(insertionIndex, 0, dateEntry);
    insertionIndex++;
  }
}
export default {
  name: 'information',
  mixins: [userLocation],
  components: {
    WlHeader,
    TzFooter,
    TzSearch,
    WlModalForm,
    WlTrustBadges
  },
  props: {
    footer: {
      type: Object,
      default: () => require(`@/assets/cms/components/footer.json`)
    },
    header: {
      type: Object,
      default: () => require(`@/assets/cms/components/header.json`)
    }
  },
  computed: {
    isHome() {
      return this.path === '/';
    },
    isLanding() {
      return this.$route?.name?.includes('flow.');
    },
    isLandingResultsAlt() {
      return this.routeName === 'flow.alt-results';
    },
    isLetterPage() {
      return this.$route.path.includes('names-by-letter');
    },
    isSearchResults() {
      return (
        this.$route.path.includes('searching') ||
        this.$route.path.includes('results')
      );
    },
    isSeoSearch() {
      return this.$route?.name?.includes('seo.');
    },
    logoPath() {
      if (this.isLanding) {
        return this.$route.fullPath;
      }
      return '/';
    },
    searchOffsetElement() {
      // TODO: Only return until elements are added to page
      return {
        offsetTop: 0,
        offsetHeight: 20
      };

      const bannerSlots = this.$refs.insideContent.querySelector(
        '.tz-banner__slots'
      );

      // If there is a banner search, show and hide header search when scrolled past ex. Home Page
      if (bannerSlots && bannerSlots.offsetHeight > 0) {
        return bannerSlots;
      }

      const bannerDesc = this.$refs.insideContent.querySelector(
        '.tz-banner__desc'
      );

      // If there is a banner with a header, show and hide search when scrolled past ex. Privacy Policy
      if (bannerDesc && bannerDesc.offsetHeight > 0) {
        return bannerDesc;
      }

      // Otherwise default to breadcrumbs ex. City Results Page
      return this.$refs.insideContent.querySelector('.tz-breadcrumbs');
    },
    isEmptyQuery() {
      return Object.keys(this.$route.query).length === 0;
    },
    isCornerStone() {
      return /cornerstone/g.test(this.routeName);
    },
    isPasswordPage() {
      return /password/g.test(this.routeName);
    },
    landingDisclaimer() {
      return this.footer.landing_disclaimer;
    },
    showDesktopSearchBarAtAllTimes() {
      return (
        this.routeName.includes('privacy-policy') ||
        this.routeName.includes('terms-conditions') ||
        this.routeName.includes('optout') ||
        this.$route.path.includes('login') ||
        this.routeName.includes('trending') ||
        this.routeName.includes('top') ||
        this.isSeoSearch ||
        this.routeName.includes('index.letter')
      );
    },
    showDesktopSearchBarWhenFixedOnly() {
      return (
        this.routeName.includes('cornerstone') ||
        this.routeName.includes('home') ||
        this.routeName.includes('contact')
      );
    },
    isSeoPhone() {
      return this.routeName === 'seo.phone';
    },
    isSeoAddress() {
      return this.routeName === 'seo.address';
    },
    isStaticPage() {
      return this.isHome || this.isCornerStone;
    },
    seoPagesClass() {
      // Prevents an empty class attribute from being added
      if (!this.isSeoSearch) {
        return;
      }

      return 'ic-seo';
    }
  },
  async created() {
    this.path = this.$route.path;
    const { query, params } = this.$route;

    if (this.isSeoSearch) {
      this.params.firstName = formatNameCaseWithSplitterAndSpacer({
        name: params.firstName,
        separator: '+',
        spacer: ' '
      });
      this.params.lastName = formatNameCaseWithSplitterAndSpacer({
        name: params.lastName,
        separator: '_',
        spacer: ' '
      });
      if (params.state) {
        this.params.state = params.state.replace(/-/g, ' ')?.toUpperCase();
        this.params.state = getStateAbbrFromName({ state: this.params.state });
      }
      if (params.city) {
        this.params.city = formatCity({ city: params.city });
      }
      if (params.phone) {
        this.params.phoneNumber = formatPhoneNumber({
          phoneNumber: params.phone
        });
      }
      this.updatePrefillFields(this.params);
    }

    if (!this.isEmptyQuery && !this.isSeoSearch) {
      const stateUpper = query.state?.toUpperCase();
      this.updatePrefillFields({ ...query, state: stateUpper });
    }

    this.setMobileText();

    if (this.isSeoSearch || this.isSearchResults) {
      // locking seo search and landing pages into having the search bar in view at all times
      this.isFixed = true;
    }

    if (this.isLandingResultsAlt) {
      this.isFixed = false;
    }
  },
  data() {
    return {
      fallbackFields: [{ aid: 26 }, {}],
      isFixed: false,
      logoSize: { height: '40', width: '158' },
      logoSmallSize: { height: '29', width: '28' },
      mobileSearchActive: false,
      menuLinksDesktopCount: 3,
      isMobile: false,
      page: {
        path: this.$route.path,
        name: this.$route.name ?? 'error'
      },
      path: null,
      peopleURL: 'https://information.com',
      phoneURL: 'https://information.com/reverse-phone-lookup/',
      prefillFields: {
        firstName: '',
        lastName: '',
        city: '',
        state: this.$store.getters['location/state'],
        phoneNumber: ''
      },
      searchNotVisibleOffset: 0,
      mobileSearchText: '',
      params: {},
      inputs: [],
      routeName: this.$route.name ?? 'error',
      altResultIds: [
        'gr05eg',
        'capt06',
        '1ll1ca',
        '1ll1rp',
        '3lv1s',
        'h4rm0',
        '81llv2',
        '81nr0',
        'c4l1',
        'r1t3s',
        'r0b3rt',
        'r0bpr0g',
        'n0f0rm',
        '8170',
        'r0bf0rm',
        'c0p1',
        'c0p2'
      ]
    };
  },
  methods: {
    showFullToggleMenu() {
      return this.isMobile || this.showDesktopSearchBar();
    },
    showDesktopSearchBar() {
      if (this.isMobile) {
        return true;
      }
      return (
        this.showDesktopSearchBarAtAllTimes ||
        (this.isFixed && this.showDesktopSearchBarWhenFixedOnly)
      );
    },
    setIsMobile(e) {
      this.isMobile = e.matches;
    },
    handleScroll() {
      this.searchNotVisibleOffset =
        this.searchOffsetElement.offsetTop +
        this.searchOffsetElement.offsetHeight;
      this.isFixed = window.scrollY >= this.searchNotVisibleOffset;
    },
    mobileSearchOpen() {
      this.mobileSearchActive = true;
    },
    mobileSearchClose() {
      this.mobileSearchActive = false;
      this.mobileSearchCloseAndSelectButton();
    },
    mobileSearchCloseAndSelectButton() {
      const phoneCurrentButton = document.querySelector(
        'button[aria-label="Phone Search, Current"]'
      );
      const peopleCurrentButton = document.querySelector(
        'button[aria-label="People Search, Current"]'
      );
      const resetBtnToSearchType = document.querySelector(
        `button[aria-label="${
          this.isSeoPhone ? 'Phone Search' : 'People Search'
        }"]`
      );
      if (this.isSeoPhone && phoneCurrentButton) {
        return;
      }
      if (!this.isSeoPhone && peopleCurrentButton) {
        return;
      }
      this.isMobile && resetBtnToSearchType.click();
    },
    setMobileText() {
      const { query } = this.$route;
      this.mobileSearchText = this.header.mobile_search_label;

      if (this.params.firstName || this.params.lastName) {
        this.mobileSearchText = `${this.params.firstName} ${this.params.lastName}`;

        if (this.params.city) {
          this.mobileSearchText += ` in ${this.params.city}, ${this.params.state}`;
        } else if (this.params.state) {
          this.mobileSearchText += ` in ${this.params.state}`;
        }
      }

      if (query.firstName || query.lastName) {
        this.mobileSearchText = `${query.firstName || ''} ${query.lastName ||
          ''}`;

        if (query.city) {
          const formattedCity = setTitleCase({
            text: query.city.replace(/-|_/g, ' ')
          });
          this.mobileSearchText += ` in ${formattedCity}, ${query.state}`;
        } else if (query.state) {
          this.mobileSearchText += ` in ${query.state}`;
        }
      }
      if (this.params.phoneNumber) {
        this.mobileSearchText = formatPhoneNumber({
          phoneNumber: this.params.phoneNumber
        });
      }
    },
    setScrollEvent() {
      // Don't show banner in header on scroll if there is not an element to offset from
      if (!this.searchOffsetElement) return;
      window.addEventListener('scroll', this.handleScroll, { passive: true });
    },
    updatePrefillFields(updatedFields) {
      this.prefillFields = Object.assign({}, this.prefillFields, updatedFields);
    },
    removeInputError(inputIndex) {
      this.inputs[inputIndex].errorMsg = null;
    },
    resetNameInputErrors() {
      if (this.isSeoPhone) {
        this.inputs[0].errorMsg = null;
      } else {
        this.inputs[0].errorMsg = null;
        this.inputs[1].errorMsg = null;
      }
    },
    navigateToSeoPhone() {
      const phone = this.inputs[0].defaultValue;
      const phoneParam = dashedPhoneNumber({
        phoneNumber: phone.replace(/\D+/g, '')
      });

      if (!phone?.trim()) {
        this.inputs[0].errorMsg = this.isMobile
          ? 'Required'
          : 'Phone number is Required';
        return;
      } else if (phoneParam.length !== 12) {
        this.inputs[0].errorMsg = ' ';
        return;
      }

      // Uses router locally and a hardcoded url for prod to prevent it from breaking
      if (process.env.NODE_ENV === 'development') {
        const { href } = this.$router.resolve({
          name: 'seo.phone',
          params: {
            phone: phoneParam
          }
        });

        window.location.href = `${href}/`;
      } else {
        window.location = `https://information.com/reverse-phone-lookup/${phoneParam}/`;
      }
    },
    navigateToSeoAddress() {
      const streetParam = this.inputs[0].defaultValue.replace(/ /g, '-');
      const cityParam = this.inputs[1].defaultValue.replace(/ |-/g, '_');
      const stateParam = getStateAbbrFromName({
        state: this.inputs[2].defaultValue
      });

      if (!streetParam?.trim() || !cityParam?.trim()) {
        if (!streetParam?.trim()) {
          this.inputs[0].errorMsg = this.isMobile
            ? 'Required'
            : 'Street Address is Required';
        }
        if (!cityParam?.trim()) {
          this.inputs[1].errorMsg = this.isMobile
            ? 'Required'
            : 'City is Required';
        }
        return;
      }

      const { href } = this.$router.resolve({
        name: 'seo.address',
        params: {
          street: streetParam,
          city: cityParam,
          state: stateParam
        }
      });
      window.location.href = `${href}/`;
    },
    navigateToPeopleFlow() {
      if (this.isSeoPhone) {
        return this.navigateToSeoPhone();
      }

      if (this.isSeoAddress) {
        return this.navigateToSeoAddress();
      }

      const firstName = this.inputs[0].defaultValue.toLowerCase();
      const lastName = this.inputs[1].defaultValue.toLowerCase();
      const city = this.inputs[2].defaultValue
        ? setTitleCase({ text: this.inputs[2].defaultValue }).replace(/ /g, '_')
        : '';
      const state = this.inputs[3].defaultValue
        ? getStateAbbrFromName({ state: this.inputs[3].defaultValue })
        : '';
      // TODO: Use method from js submodule once its available
      let params = resultHelper.convertArrayOfObjectsToObject(
        this.header.cp_search_params,
        'name',
        'value'
      );

      if (!firstName) {
        this.inputs[0].errorMsg = this.isMobile
          ? 'Required'
          : 'First Name is Required';
      }
      if (!lastName) {
        this.inputs[1].errorMsg = this.isMobile
          ? 'Required'
          : 'Last Name is Required';
      }
      if (!firstName || !lastName) {
        return;
      }

      delete this.$route.query.age;

      params = {
        ...this.$route.query,
        firstName,
        lastName,
        city,
        state
      };

      const href = this.isLanding
        ? this.$router.resolve({
            name:
              this.altResultIds.includes(this.$route.params.landingID) ||
              this.$route.name === 'flow.alt-results'
                ? 'flow.alt-results'
                : 'flow.results',
            query: params
          }).href
        : this.getSeoHref({
            firstName,
            lastName,
            city: this.inputs[2].defaultValue.toLowerCase().replace(' ', '-'),
            state: this.inputs[3].defaultValue
          });

      // To get header search to work on static cornerstone pages, it needs to point to prod
      if (this.isStaticPage) {
        window.location = `https://information.com${href}`;
      } else {
        window.location.href = href;
      }
    },
    openHeaderModal() {
      document.body.classList.add('body--show-modal');
    },
    closeHeaderModal() {
      this.resetNameInputErrors();
      document.body.classList.remove('body--show-modal');
    },
    getSeoHref(params) {
      let url = `/people/${params.firstName}-${params.lastName}/`;

      if (params.state && !params.city) {
        url += `${params.state.toLowerCase()}/`;
      }
      if (params.city && params.state) {
        url += `${params.state.toLowerCase()}/${params.city.toLowerCase()}/`;
      }
      return url;
    },
    getModalTitle() {
      if (this.isSeoPhone) {
        return this.header.modal.phone_title;
      }
      if (this.isSeoAddress) {
        return this.header.modal.address_title;
      }
      return this.header.modal.title;
    },
    getModalText() {
      if (this.isSeoPhone) {
        return this.header.modal.phone_text;
      }
      if (this.isSeoAddress) {
        return this.header.modal.address_text;
      }
      return '';
    },
    async redirectToDashboardRoute() {
      if (this.isStaticPage) {
        return (window.location = `https://information.com/dashboard/`);
      }
      let redirectRouteName;
      switch (this.$route.name) {
        case 'privacy-policy':
          redirectRouteName = 'dashboard-privacy-policy';

          break;
        case 'terms-conditions':
          redirectRouteName = 'dashboard-terms-conditions';

          break;
        default:
          redirectRouteName = 'dashboard-home';
      }
      await this.$router.push({ name: redirectRouteName });
    },
    /**
     * Takes user to phone/people SEO page
     * @param {object} event
     */
    navigateToSeo(event) {
      const {
        firstName,
        lastName,
        city,
        state,
        phoneNumber,
        searchType
      } = event;
      let searchLink = '';

      switch (searchType) {
        case 'phone':
          const rawPhoneNumber = phoneNumber.replace(/\D+/g, '');
          const phoneParam = dashedPhoneNumber({ phoneNumber: rawPhoneNumber });
          const seoPhoneLink = this.$router.resolve({
            name: 'seo.phone',
            params: {
              phone: phoneParam
            },
            query: { ...this.$route.query }
          }).href;
          searchLink = seoPhoneLink;
          break;
        case 'people':
          let seoRoute = 'seo.root';
          if (state && city) {
            seoRoute = 'seo.city';
          } else if (state) {
            seoRoute = 'seo.state';
          }
          const { href } = this.$router.resolve({
            name: seoRoute,
            query: { ...this.$route.query },
            params: {
              firstName: firstName,
              lastName: lastName,
              city: city.replace(' ', '-'),
              state: state
                ? getStateFromAbbr({
                    stateAbbr: state,
                    includeTerritories: true
                  }).replace(' ', '-')
                : ''
            }
          });
          searchLink = href.toLowerCase();
          break;
      }

      window.location = searchLink + '/';
    }
  },
  async mounted() {
    // Add Sift
    if (!this.isSeoSearch) {
      const siftConversion = document.createElement('script');
      siftConversion.innerHTML =
        `
        var _user_id = ''; /* Set user id to '' or email to match user id when order is created */
        var _session_id = '` +
        getSessionId() +
        `'; /* Set to a unique session ID for the visitor's current browsing session. */

        var _sift = window._sift = window._sift || [];
        _sift.push(['_setAccount', '` +
        this.$config.defaultVars.siftBeaconKey +
        `']);
        _sift.push(['_setUserId', _user_id]);
        _sift.push(['_setSessionId', _session_id]);
        _sift.push(['_trackPageview']);

        function sendPageView() { /* Method for use in react pages */
            _sift.push(['_trackPageview']);
        }

        (function() {
            function ls() {
                var e = document.createElement('script');
                e.src = 'https://cdn.sift.com/s.js';
                document.body.appendChild(e);
            }
            if (window.attachEvent) {
                window.attachEvent('onload', ls);
            } else {
                window.addEventListener('load', ls, false);
            }
        })();
      `;
      document.head.appendChild(siftConversion);
    }

    const isUserLoggedIn = this.$authHelper.isUserLoggedInSync();

    if (isUserLoggedIn) {
      const userCurrent = await this.$authHelper.getCurrentUser();
      if (userCurrent === 'Access Denied') {
        await this.$authHelper.logoutUser('/login/');
      }
    }

    if (isUserLoggedIn) {
      await this.redirectToDashboardRoute();
    }

    if (!this.isSeoSearch && !this.isSearchResults) {
      this.handleScroll();
      this.setScrollEvent();
    }

    // Remove any registered service workers
    unregisterAndClearCaches();

    window.onpageshow = function(event) {
      /* Safari Back Button Persistent Cache Fix */
      if (event.persisted) {
        window.location.reload();
      }
    };
    let queryState = '';
    if (this.routeName === 'optout-request') {
      queryState = this.$route.query.state
        ? getStateFromAbbr({
            stateAbbr: this.$route.query.state,
            includeTerritories: true
          })
            .toLowerCase()
            .replace(/ /g, '-')
        : '';
    } else {
      const hasLocationAbbr = getStateFromAbbr({
        stateAbbr: this.$route.params.state,
        includeTerritories: true
      });
      queryState = hasLocationAbbr?.toLowerCase() ?? this.$route.query.state;
    }

    // Format state for dropdown and add hyphen if state has space
    const formattedState = (queryState || this.$route.params.state || '')
      .toLowerCase()
      .replace(/ /g, '-');

    if (this.isSeoPhone) {
      this.inputs = [
        {
          name: 'phone',
          type: 'tel',
          validationType: 'tel',
          defaultValue: this.$route.params.phone
            ? formatPhoneNumber({
                phoneNumber: this.$route.params.phone.replace(/\D+/g, '')
              })
            : '',
          optionalText: 'Phone Number',
          errorMsg: null
        }
      ];
    }

    if (this.isSeoAddress) {
      this.inputs = [
        {
          name: 'street',
          type: 'text',
          defaultValue: this.$route.params.street
            ? this.$route.params.street.replace(/-/g, ' ')
            : '',
          validationType: 'street',
          optionalText: 'Street',
          errorMsg: null
        },
        {
          name: 'city',
          type: 'text',
          defaultValue:
            formatCity({ city: this.$route.query.city }) ||
            formatCity({ city: this.$route.params.city }) ||
            '',
          validationType: 'text',
          optionalText: 'City',
          errorMsg: null
        },
        {
          name: 'state',
          type: 'select',
          defaultValue: formattedState,
          valueOptions: getStatesForDropdown({
            useStateAbbrAsValue:
              this.isSeoSearch || !this.isLanding ? false : true
          }),
          optionalText: 'State'
        }
      ];
    }

    if (!this.isSeoAddress && !this.isSeoPhone) {
      this.inputs = [
        {
          name: 'firstName',
          type: 'text',
          defaultValue:
            parseParams({
              word: this.$route.query.firstName,
              splitBy: ['_'],
              parseBy: ' '
            }) ||
            parseParams({
              word: this.$route.params.firstName,
              splitBy: ['_'],
              parseBy: ' '
            }),
          validationType: 'text',
          optionalText: 'First Name',
          errorMsg: null
        },
        {
          name: 'lastName',
          type: 'text',
          defaultValue:
            parseParams({
              word: this.$route.query.lastName,
              splitBy: ['_'],
              parseBy: ' '
            }) ||
            parseParams({
              word: this.$route.params.lastName,
              splitBy: ['_'],
              parseBy: ' '
            }),
          validationType: 'text',
          optionalText: 'Last Name',
          errorMsg: null
        },
        {
          name: 'city',
          type: 'text',
          defaultValue:
            formatCity({ city: this.$route.query.city }) ||
            formatCity({ city: this.$route.params.city }) ||
            '',
          validationType: 'text',
          optionalText: 'City'
        },
        {
          name: 'state',
          type: 'select',
          defaultValue: formattedState,
          valueOptions: getStatesForDropdown({
            useStateAbbrAsValue:
              this.isSeoSearch || !this.isLanding ? false : true,
            includeAllStatesOption: true
          }),
          optionalText: 'State'
        }
      ];
    }

    mediaQueryListener({
      selector: mobileMediaQuery,
      handleMethod: this.setIsMobile
    });
    this.isMobile = mobileMediaQuery.matches;
  },
  beforeDestroy() {
    mobileMediaQuery.removeEventListener('change', this.setIsMobile);
  },
  head() {
    const head = {
      meta: [],
      link: [],
      script: [],
      __dangerouslyDisableSanitizers: ['script']
    };

    const hasNoCrawling = /optout-*|flow.*/.test(this.routeName);

    if (!head.meta) {
      head.meta = [];
    }

    // Add VWO
    if (this.isLanding || this.isHome || this.isCornerStone) {
      head.script.push({
        innerHTML: `
        var _vwo_code=(function(){
          var account_id=43640,
              settings_tolerance=2000,
              library_tolerance=2500,
              use_existing_jquery=false,
              is_spa = 1,
              /* DO NOT EDIT BELOW THIS LINE */
              f=false,d=document;return{use_existing_jquery:function(){return use_existing_jquery;},library_tolerance:function(){return library_tolerance;},finish:function(){if(!f){f=true;var a=d.getElementById('_vis_opt_path_hides');if(a)a.parentNode.removeChild(a);}},finished:function(){return f;},load:function(a){var b=d.createElement('script');b.src=a;b.type='text/javascript';b.innerText;b.onerror=function(){_vwo_code.finish();};d.getElementsByTagName('head')[0].appendChild(b);},init:function(){settings_timer=setTimeout('_vwo_code.finish()',settings_tolerance);var a=d.createElement('style'),b='body{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}',h=d.getElementsByTagName('head')[0];a.setAttribute('id','_vis_opt_path_hides');a.setAttribute('type','text/css');if(a.styleSheet)a.styleSheet.cssText=b;else a.appendChild(d.createTextNode(b));h.appendChild(a);this.load('//dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+(+is_spa)+'&r='+Math.random());return settings_timer;}};
        }());_vwo_settings_timer=_vwo_code.init();
      `
      });
      head.script.push({
        type: 'text/javascript',
        src: 'https://www.chkppl.com/scripts/sdk/everflow.js'
      });
      head.script.push({
        innerHTML: `
        window.addEventListener('load', function(event) {
            EF.click({
              offer_id: EF.urlParameter('oid'),
              affiliate_id: EF.urlParameter('aid'),
              sub1: EF.urlParameter('sub1'),
              sub2: EF.urlParameter('sub2'),
              sub3: EF.urlParameter('sub3'),
              sub4: EF.urlParameter('sub4'),
              sub5: EF.urlParameter('sub5'),
              uid: EF.urlParameter('uid'),
              transaction_id: EF.urlParameter('transaction_id')
            });
        });
        `
      });
    }

    // Add Google Tag Manager
    head.script.push({
      async: true,
      src: 'https://www.googletagmanager.com/gtm.js?id=AW-11137963343'
    });
    head.script.push({
      innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11137963343', { 'allow_enhanced_conversions': true });
        `
    });

    // If route has no crawling add noindex nofollow noarchive and return early
    if (hasNoCrawling) {
      head.meta.push({
        name: 'robots',
        hid: 'robots',
        content: 'noindex, nofollow, noarchive'
      });

      return head;
    }
    const isSeoPage = /seo/.test(this.$route.name);
    const website = `https://information.com`;
    const path =
      this.$route.path.slice(-1) === '/'
        ? this.$route.path
        : this.$route.path + '/';

    if (!isSeoPage && this.$route.name) {
      // seo pages have their own canonical link logic that accounts for title casing
      head.link = [
        {
          rel: 'canonical',
          href: `${website}${path.toLowerCase()}`
        }
      ];
    }

    // Push org schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'Information',
        url: website,
        alternateName: 'Information.com',
        logo: `${website}/site/information/images/logo.webp`,
        contactPoint: [
          {
            '@type': 'ContactPoint',
            email: 'contact@information.com',
            url: `${website}/contact/`,
            contactType: 'Customer Service',
            areaServed: 'US'
          }
        ]
      }
    });

    if (/seo./.test(this.routeName)) {
      return head;
    }

    // Setup nav links for nav schema
    const navLinks = []
      .concat(this.footer.primary_list)
      .concat(this.footer.secondary_list)
      .concat(this.footer.tertiary_list)
      .concat(this.footer.quarternary_list)
      .concat(
        [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map(letter => ({
          url: `/names-by-letter/${letter.toLowerCase()}/`,
          name: letter
        }))
      );

    const navSchema = [];
    // Loop through links to build nav schema
    for (let i = 0; i < navLinks.length; i++) {
      const link = navLinks[i].url.startsWith('/')
        ? website + navLinks[i].url
        : navLinks[i].url;
      navSchema.push({
        '@type': 'SiteNavigationElement',
        position: i + 1,
        '@id': link,
        url: link,
        name: navLinks[i].label
      });
    }
    // Push nav schema to head
    head.script.push({
      type: 'application/ld+json',
      json: {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: navSchema
      }
    });

    return head;
  }
};
</script>
