<template>
  <TzModal
    :open="open"
    @tz-modal-close="closeModal"
    role="dialog"
    aria-modal="true"
  >
    <div class="wl-modal-form">
      <p class="wl-modal-form__title">{{ modalTitle }}</p>
      <p class="wl-modal-form__text" v-if="modalText">{{ modalText }}</p>
      <slot name="above-form-content" />
      <form
        method="POST"
        @submit.prevent="handleConfirmButton"
        @change="handleChange"
      >
        <div class="wl-modal-form__wrapper">
          <div
            v-for="(input, index) in inputs"
            :key="index"
            class="wl-modal-form__form-group"
          >
            <p
              v-if="input.errorMsg"
              v-text="input.errorMsg"
              class="wl-modal-form__error"
            />
            <label
              class="wl-modal-form__optional-text"
              :for="input.name"
              v-if="input.optionalText"
              v-text="input.optionalText"
            />
            <select
              v-if="input.type === 'select'"
              :name="input.name"
              :ref="input.name"
              v-model="input.defaultValue"
              class="wl-modal-form__input-field wl-modal-form__input-field--select"
              @change="localChange(input)"
              :disabled="input.disabled"
            >
              <option
                v-for="option in input.valueOptions"
                :value="option.value"
                :disabled="option.disabled"
                :hidden="option.hidden"
                :selected="option.selected"
                >{{ option.name }}</option
              >
            </select>
            <input
              v-else-if="input.type === 'button'"
              :type="input.type"
              v-for="choices in input.valueOptions"
              :value="choices.value"
              class="wl-modal-form__input-field wl-modal-form__input-field--button"
              v-touch:tap="handleChoiceClick"
            />
            <input
              v-else
              :type="input.type"
              :name="input.name"
              :ref="input.name"
              :id="input.name"
              v-model.trim="input.defaultValue"
              :maxlength="input.type === 'tel' && 14"
              :placeholder="input.placeholder"
              :disabled="input.disabled"
              class="wl-modal-form__input-field"
              :class="
                input.optionalText
                  ? 'wl-modal-form__input-field--has-optional-text'
                  : ''
              "
              @change="localChange(input)"
              @keydown.enter="handleConfirmButton"
              @input="handleChange($event, input)"
              @focus="handleFocus(index)"
            />
          </div>
        </div>
        <div class="um-optout__captcha-wrapper" v-if="enableHcaptcha">
          <span class="um-optout__captcha-error" v-if="captchaErr">
            {{ captchaErr }}
          </span>
          <vue-hcaptcha
            ref="hcaptcha"
            class="um-optout__captcha"
            :sitekey="hcaptcha.sitekey"
            size="invisible"
            @verify="onVerify"
            @error="onError"
          />
        </div>
        <div class="wl-modal-form__btn-wrapper" v-if="hasConfirmCancelButtons">
          <button
            v-if="confirmButtonText"
            class="wl-modal-form__btn wl-modal-form__btn--confirm"
            :class="{
              'wl-modal-form__btn--loading':
                showLoadingButton && hasClickedConfirmButton
            }"
            v-touch:tap.stop.prevent="handleConfirmButton"
          >
            {{ confirmButtonText }}
          </button>
          <div
            v-if="cancelButtonText"
            class="wl-modal-form__btn wl-modal-form__btn--cancel"
            v-touch:tap.stop.prevent="handleCancelButton"
            :disabled="shouldDisableCancel"
          >
            {{ cancelButtonText }}
          </div>
        </div>
      </form>
    </div>
  </TzModal>
</template>

<script>
import TzModal from '@trazi/tz-modal/src/tz-modal.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import getRegex from '@/assets/js/shared/helpers/getRegex';

export default {
  name: 'WlModalForm',
  components: {
    TzModal,
    TzMarkdown,
    VueHcaptcha
  },
  data() {
    return {
      hasClickedConfirmButton: false,
      verified: false,
      token: null,
      captchaErr: null,
      phoneRaw: ''
    };
  },
  computed: {
    hasConfirmCancelButtons() {
      return this.confirmButtonText || this.cancelButtonText;
    },
    shouldDisableCancel() {
      return this.allowCancelWithoutConfirm
        ? false
        : this.hasClickedConfirmButton;
    },
    phoneAreaCode() {
      return (
        '(' +
        this.phoneRaw.substr(0, 3) +
        (this.phoneRaw.length > 3 ? ') ' : '')
      );
    },
    phonePrefix() {
      return this.phoneRaw.substr(3, 3);
    },
    phoneLine() {
      return this.phoneRaw.length < 7 ? '' : '-' + this.phoneRaw.substr(6, 4);
    }
  },
  props: {
    // Optional - True value prevents closing on scrim click
    allowCancelWithoutConfirm: {
      type: Boolean,
      default: true
    },
    // Optional cancel button text
    cancelButtonText: {
      type: String,
      default: null
    },
    // Optional confirmation button text
    confirmButtonText: {
      type: String,
      default: null
    },
    // Optional method to handle closing of modal
    closeModal: {
      type: Function,
      default: null
    },
    // Handles whether we want to show the hcaptcha or not
    enableHcaptcha: {
      type: Boolean,
      default: false
    },
    // Hcaptcha object, should have execute method and sitekey
    hcaptcha: {
      type: Object,
      default: () => require(`@/assets/cms/components/hcaptcha.json`)
    },
    // Array of inputs to add to the form
    inputs: {
      type: Array,
      required: true
    },
    // Optional preformatted string of modal subtext
    modalText: {
      type: String,
      default: null
    },
    // Preformatted string of modal title
    modalTitle: {
      type: String,
      required: true
    },
    // Add/Remove the close class on the modal
    open: {
      type: Boolean,
      required: true
    },
    // Optional method to emit changes on inputs
    onChange: {
      type: Function,
      default: null
    },
    // Handles whether we want to show the hcaptcha or not
    enableHCaptcha: {
      type: Boolean,
      default: false
    },
    // Display loading icon when submitting form
    showLoadingButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    localChange: function(value) {
      if (!this.onChange) {
        return;
      }
      this.onChange(value);
    },
    onTextInput: function(event, input) {
      const invalidRegex = getRegex({ regexType: 'peopleInvalidRegex' });
      if (invalidRegex.test(input.defaultValue)) {
        event.preventDefault();
        input.defaultValue = input.defaultValue.replace(invalidRegex, '');
      } else return true;
    },
    onPhoneInput: function(event, input) {
      const nonNumericCharacters = /[^0-9]+/g;
      const sanitizedInput = input.defaultValue.replace(
        nonNumericCharacters,
        ''
      );
      if (!sanitizedInput) {
        event.preventDefault();
        input.defaultValue = '';
      }

      this.phoneRaw = sanitizedInput;

      if (this.phoneRaw !== '') {
        input.defaultValue = `${this.phoneAreaCode}${this.phonePrefix}${this.phoneLine}`;
      }
    },
    /**
     * Prevent invalid characters on street input
     * @param {object} input
     */
    onStreetInput(input) {
      const invalidStreetChars = /[^a-zA-Z0-9\s#.-]/g;

      input.defaultValue = input.defaultValue.replace(invalidStreetChars, '');
    },
    /**
     * Prevent invalid characters on zip input
     * @param {object} input
     */
    onZipInput(input) {
      const invalidZipChars = /[^0-9]/g;

      input.defaultValue = input.defaultValue
        .replace(invalidZipChars, '')
        .substring(0, 5);
    },
    handleChange($event, input) {
      this.$emit('wl-modal-form-change', $event);
      if (input) {
        this.validateText($event, input);
      }
    },
    handleFocus(inputIndex) {
      this.$emit('wl-modal-input-focus', inputIndex);
    },
    handleConfirmButton() {
      this.hasClickedConfirmButton = true;

      if (this.enableHcaptcha) {
        // Handles hcaptcha trigger programmatically
        this.$refs.hcaptcha.execute();
        return;
      }

      this.$emit('wl-modal-form-confirm');
    },
    handleCancelButton(event) {
      event.preventDefault();
      this.$emit('wl-modal-form-cancel');
    },
    handleChoiceClick(event) {
      const buttonValue = event.target.value;
      this.$emit('wl-modal-form-clicked', buttonValue);
    },
    validateText($event, input) {
      if (!input.validationType) return;

      switch (input.validationType) {
        case 'text':
          this.onTextInput($event, input);
          break;
        case 'tel':
          this.onPhoneInput($event, input);
          break;
        case 'street':
          this.onStreetInput(input);
          break;
        case 'zip':
          this.onZipInput(input);
          break;
      }
    },
    onVerify(token) {
      this.verified = true;
      this.token = token;

      if (this.captchaErr) {
        this.captchaErr = null;
      }

      if (this.token && this.enableHcaptcha) {
        this.$emit('wl-modal-form-confirm', this.token);
      }
    },
    onError(err) {
      this.token = null;
      this.captchaErr = err;
    }
  }
};
</script>
