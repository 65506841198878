import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
const options = {"environment":"production","appVersion":"v9.0.0","publishRelease":true,"releaseStage":"production","apiKey":"f29b1fc72dc2678abb8db484dc6301e3","autoAssignRelease":true}
options.plugins = [new BugsnagPluginVue()]

let bugsnagClient

export default function ({ app }, inject) {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start(options)
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  } else {
    bugsnagClient = Bugsnag._client
  }

  inject('bugsnag', bugsnagClient)
}
