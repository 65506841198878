import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a35e415 = () => interopDefault(import('../pages/home/home.vue' /* webpackChunkName: "" */))
const _3671e4df = () => interopDefault(import('../pages/cornerstone/people-lookup.vue' /* webpackChunkName: "" */))
const _333f4c87 = () => interopDefault(import('../pages/cornerstone/public-records.vue' /* webpackChunkName: "" */))
const _06807b22 = () => interopDefault(import('../pages/cornerstone/phone-lookup.vue' /* webpackChunkName: "" */))
const _49b06434 = () => interopDefault(import('../pages/cornerstone/email-lookup.vue' /* webpackChunkName: "" */))
const _d7c0285e = () => interopDefault(import('../pages/cornerstone/background-checks.vue' /* webpackChunkName: "" */))
const _4f7910ca = () => interopDefault(import('../pages/cornerstone/address.vue' /* webpackChunkName: "" */))
const _78c22f41 = () => interopDefault(import('../pages/cornerstone/criminal-records.vue' /* webpackChunkName: "" */))
const _11ead9a4 = () => interopDefault(import('../pages/cornerstone/dos-donts.vue' /* webpackChunkName: "" */))
const _2c023586 = () => interopDefault(import('../pages/cornerstone/faq.vue' /* webpackChunkName: "" */))
const _53b679b7 = () => interopDefault(import('../pages/contact/contact.vue' /* webpackChunkName: "" */))
const _a832b3aa = () => interopDefault(import('../pages/terms-conditions/index.vue' /* webpackChunkName: "" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _6ae02208 = () => interopDefault(import('../pages/shared/forgot-password/index.vue' /* webpackChunkName: "" */))
const _15e4431b = () => interopDefault(import('../pages/shared/new-password/index.vue' /* webpackChunkName: "" */))
const _65ca94ca = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "" */))
const _2f9e5638 = () => interopDefault(import('../pages/dashboard/home.vue' /* webpackChunkName: "" */))
const _b1dc3390 = () => interopDefault(import('../pages/dashboard/account.vue' /* webpackChunkName: "" */))
const _7f6a65cd = () => interopDefault(import('../pages/dashboard/billing-history.vue' /* webpackChunkName: "" */))
const _cd5ecd98 = () => interopDefault(import('../pages/dashboard/search-history.vue' /* webpackChunkName: "" */))
const _9bf2c6f4 = () => interopDefault(import('../pages/dashboard/report-monitor.vue' /* webpackChunkName: "" */))
const _54798b81 = () => interopDefault(import('../pages/dashboard/results.vue' /* webpackChunkName: "" */))
const _41a0eb52 = () => interopDefault(import('../pages/dashboard/search/address.vue' /* webpackChunkName: "" */))
const _66f2ff0c = () => interopDefault(import('../pages/dashboard/search/email.vue' /* webpackChunkName: "" */))
const _10ce394c = () => interopDefault(import('../pages/dashboard/search/phone.vue' /* webpackChunkName: "" */))
const _0086687a = () => interopDefault(import('../pages/dashboard/terms-conditions.vue' /* webpackChunkName: "" */))
const _2adde2dc = () => interopDefault(import('../pages/dashboard/privacy-policy.vue' /* webpackChunkName: "" */))
const _974d14e2 = () => interopDefault(import('../pages/reports/person.vue' /* webpackChunkName: "" */))
const _092de2a4 = () => interopDefault(import('../pages/reports/phone.vue' /* webpackChunkName: "" */))
const _be88c2ac = () => interopDefault(import('../pages/reports/address.vue' /* webpackChunkName: "" */))
const _6a38beeb = () => interopDefault(import('../pages/dashboard/reactivate.vue' /* webpackChunkName: "" */))
const _196441bf = () => interopDefault(import('../pages/dashboard/cancel.vue' /* webpackChunkName: "" */))
const _73b4fa32 = () => interopDefault(import('../pages/dashboard/upsell.vue' /* webpackChunkName: "" */))
const _0301f570 = () => interopDefault(import('../pages/dashboard/upsell2.vue' /* webpackChunkName: "" */))
const _1853eafe = () => interopDefault(import('../pages/dashboard/sex-offenders.vue' /* webpackChunkName: "" */))
const _14140b7a = () => interopDefault(import('../pages/searching/index.vue' /* webpackChunkName: "" */))
const _0a20dbf8 = () => interopDefault(import('../pages/landing/results.vue' /* webpackChunkName: "" */))
const _4c768640 = () => interopDefault(import('../pages/landing/alt-results.vue' /* webpackChunkName: "" */))
const _168b6cb3 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "" */))
const _4e54d84e = () => interopDefault(import('../pages/step/step-one.vue' /* webpackChunkName: "" */))
const _66bc8934 = () => interopDefault(import('../pages/step/step-two.vue' /* webpackChunkName: "" */))
const _e1e9c0f4 = () => interopDefault(import('../pages/step/step-three.vue' /* webpackChunkName: "" */))
const _c421512e = () => interopDefault(import('../pages/step/alt-one.vue' /* webpackChunkName: "" */))
const _9351ef62 = () => interopDefault(import('../pages/step/alt-two.vue' /* webpackChunkName: "" */))
const _620243e1 = () => interopDefault(import('../pages/step/alt-three.vue' /* webpackChunkName: "" */))
const _b9a10f6c = () => interopDefault(import('../pages/checkout/alt.vue' /* webpackChunkName: "" */))
const _19ae5b70 = () => interopDefault(import('../pages/landing/welcome.vue' /* webpackChunkName: "" */))
const _6c5bc195 = () => interopDefault(import('../pages/optout/optout.vue' /* webpackChunkName: "" */))
const _7684cdb6 = () => interopDefault(import('../pages/optout/request.vue' /* webpackChunkName: "" */))
const _483270e2 = () => interopDefault(import('../pages/optout/confirmation.vue' /* webpackChunkName: "" */))
const _302c424a = () => interopDefault(import('../pages/seo/results.vue' /* webpackChunkName: "" */))
const _6617f594 = () => interopDefault(import('../pages/redirect/seo.vue' /* webpackChunkName: "" */))
const _0393099c = () => interopDefault(import('../pages/trending/day.vue' /* webpackChunkName: "" */))
const _c49f2c5c = () => interopDefault(import('../pages/trending/index.vue' /* webpackChunkName: "" */))
const _dc790a4a = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "" */))
const _97234e22 = () => interopDefault(import('../pages/top-full-names/index.vue' /* webpackChunkName: "" */))
const _683bfd2e = () => interopDefault(import('../pages/top-full-names/state.vue' /* webpackChunkName: "" */))
const _13944a14 = () => interopDefault(import('../pages/top-last-names/index.vue' /* webpackChunkName: "" */))
const _4b05d902 = () => interopDefault(import('../pages/top-last-names/alphabet.vue' /* webpackChunkName: "" */))
const _1c88a69c = () => interopDefault(import('../pages/top-last-names/letter.vue' /* webpackChunkName: "" */))
const _cbcfeee4 = () => interopDefault(import('../pages/top-last-names/states.vue' /* webpackChunkName: "" */))
const _abf90196 = () => interopDefault(import('../pages/top-last-names/state.vue' /* webpackChunkName: "" */))
const _966c8e52 = () => interopDefault(import('../pages/bucket/index.vue' /* webpackChunkName: "" */))
const _010a3790 = () => interopDefault(import('../pages/landing/name-search.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _0a35e415,
    name: "home"
  }, {
    path: "/people/",
    component: _3671e4df,
    name: "cornerstone.people"
  }, {
    path: "/public-records-search/",
    component: _333f4c87,
    name: "cornerstone.public-records"
  }, {
    path: "/reverse-phone-lookup/",
    component: _06807b22,
    name: "cornerstone.phone"
  }, {
    path: "/email-lookup/",
    component: _49b06434,
    name: "cornerstone.email"
  }, {
    path: "/background-checks-search/",
    component: _d7c0285e,
    name: "cornerstone.background-checks"
  }, {
    path: "/reverse-address-lookup/",
    component: _4f7910ca,
    name: "cornerstone.address"
  }, {
    path: "/criminal-records-check/",
    component: _78c22f41,
    name: "cornerstone.criminal"
  }, {
    path: "/dos-and-donts/",
    component: _11ead9a4,
    name: "cornerstone.dos-donts"
  }, {
    path: "/frequently-asked-questions/",
    component: _2c023586,
    name: "cornerstone.faq"
  }, {
    path: "/contact/",
    component: _53b679b7,
    name: "contact"
  }, {
    path: "/terms-conditions/",
    component: _a832b3aa,
    name: "terms-conditions"
  }, {
    path: "/privacy-policy/",
    component: _7de68bc4,
    name: "privacy-policy"
  }, {
    path: "/login/",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/forgot-password/",
    component: _6ae02208,
    name: "forgot-password"
  }, {
    path: "/new-password/",
    component: _15e4431b,
    name: "new-password"
  }, {
    path: "/dashboard/reports/:page(\\d+)?",
    component: _65ca94ca,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/:page(\\d+)?",
    component: _2f9e5638,
    name: "dashboard-home"
  }, {
    path: "/dashboard/account/",
    component: _b1dc3390,
    name: "dashboard-account"
  }, {
    path: "/dashboard/account/billing-history/:page(\\d+)?",
    component: _7f6a65cd,
    name: "dashboard-billing-history"
  }, {
    path: "/dashboard/search-history/:page(\\d+)?",
    component: _cd5ecd98,
    name: "dashboard_search_history"
  }, {
    path: "/dashboard/monitor/:page(\\d+)?",
    component: _9bf2c6f4,
    name: "dashboard-monitor"
  }, {
    path: "/dashboard/search/",
    component: _54798b81,
    name: "dashboard-search"
  }, {
    path: "/dashboard/address/",
    component: _41a0eb52,
    name: "dashboard.address"
  }, {
    path: "/dashboard/email/",
    component: _66f2ff0c,
    name: "dashboard.email"
  }, {
    path: "/dashboard/phone/",
    component: _10ce394c,
    name: "dashboard.phone"
  }, {
    path: "/dashboard/terms-conditions/",
    component: _0086687a,
    name: "dashboard-terms-conditions"
  }, {
    path: "/dashboard/privacy-policy/",
    component: _2adde2dc,
    name: "dashboard-privacy-policy"
  }, {
    path: "/dashboard/report/person/:uuid/",
    component: _974d14e2,
    name: "dashboard-reports-person-generate"
  }, {
    path: "/dashboard/report/phone/:reportId/",
    component: _092de2a4,
    name: "dashboard-reports-phone"
  }, {
    path: "/dashboard/report/address/:street--:city-:state-:zip/",
    component: _be88c2ac,
    name: "dashboard-reports-address"
  }, {
    path: "/dashboard/report/:reportType/:reportCode/",
    component: _974d14e2,
    name: "dashboard-reports-person"
  }, {
    path: "/dashboard/reactivate/",
    component: _6a38beeb,
    name: "dashboard-reactivate"
  }, {
    path: "/dashboard/subscribe/:type",
    component: _6a38beeb,
    name: "dashboard-subscribe"
  }, {
    path: "/dashboard/cancel/",
    component: _196441bf,
    name: "dashboard.cancel"
  }, {
    path: "/dashboard/success/",
    component: _73b4fa32,
    name: "dashboard.upsell"
  }, {
    path: "/dashboard/last-chance/",
    component: _0301f570,
    name: "dashboard.upsell2"
  }, {
    path: "/dashboard/sex-offenders/",
    component: _1853eafe,
    name: "dashboard-sex-offenders"
  }, {
    path: "/pf/:landingID/searching/",
    component: _14140b7a,
    name: "flow.searching"
  }, {
    path: "/pf/:landingID/results/",
    component: _0a20dbf8,
    name: "flow.results"
  }, {
    path: "/pf/:landingID/res/",
    component: _4c768640,
    name: "flow.alt-results"
  }, {
    path: "/pf/:landingID/checkout/",
    component: _168b6cb3,
    name: "flow.checkout"
  }, {
    path: "/pf/:landingID/1/",
    component: _4e54d84e,
    name: "flow.step1"
  }, {
    path: "/pf/:landingID/2/",
    component: _66bc8934,
    name: "flow.step2"
  }, {
    path: "/pf/:landingID/3/",
    component: _e1e9c0f4,
    name: "flow.step3"
  }, {
    path: "/pf/:landingID/step-1/",
    component: _c421512e,
    name: "flow.alt1"
  }, {
    path: "/pf/:landingID/step-2/",
    component: _9351ef62,
    name: "flow.alt2"
  }, {
    path: "/pf/:landingID/step-3/",
    component: _620243e1,
    name: "flow.alt3"
  }, {
    path: "/pf/:landingID/cart/",
    component: _b9a10f6c,
    name: "flow.cart"
  }, {
    path: "/special-offer/",
    component: _19ae5b70,
    name: "flow.welcome"
  }, {
    path: "/remove-my-info/",
    component: _6c5bc195,
    name: "optout"
  }, {
    path: "/remove-my-info/request/",
    component: _7684cdb6,
    name: "optout-request"
  }, {
    path: "/remove-my-info/confirmation/:token/",
    component: _483270e2,
    name: "optout-confirmation"
  }, {
    path: "/people/:firstName-:lastName/:page(\\d+)?",
    component: _302c424a,
    name: "seo.root"
  }, {
    path: "/people/:firstName-:lastName/:state/:page(\\d+)?",
    component: _302c424a,
    name: "seo.state"
  }, {
    path: "/people/:firstName-:lastName/:state/:city/:page(\\d+)?",
    component: _302c424a,
    name: "seo.city"
  }, {
    path: "/reverse-phone-lookup/:phone/:page(\\d+)?",
    component: _302c424a,
    name: "seo.phone"
  }, {
    path: "/reverse-address-lookup/:street--:city-:state/:page(\\d+)?",
    component: _302c424a,
    name: "seo.address"
  }, {
    path: "/people-search/:firstName-:lastName/:page(\\d+)?",
    component: _6617f594,
    name: "seo.root-redirect"
  }, {
    path: "/people-search/:firstName-:lastName/:state/:page(\\d+)?",
    component: _6617f594,
    name: "seo.state-redirect"
  }, {
    path: "/people-search/:firstName-:lastName/:state/:city/:page(\\d+)?",
    component: _6617f594,
    name: "seo.city-redirect"
  }, {
    path: "/popular-names/:year-:month-:day/",
    component: _0393099c,
    name: "trending.day"
  }, {
    path: "/popular-names/:year?/",
    component: _c49f2c5c,
    name: "trending"
  }, {
    path: "/names-by-letter/:letter/:page?",
    component: _dc790a4a,
    name: "full-names-letter"
  }, {
    path: "/top-rated-full-names/",
    component: _97234e22,
    name: "top-full-names.root"
  }, {
    path: "/top-rated-full-names/:state/",
    component: _683bfd2e,
    name: "top-full-names.state"
  }, {
    path: "/top-rated-last-names/",
    component: _13944a14,
    name: "top-last-names.root"
  }, {
    path: "/top-rated-last-names/letter/",
    component: _4b05d902,
    name: "top-last-names.alphabet"
  }, {
    path: "/top-rated-last-names/letter/:letter/",
    component: _1c88a69c,
    name: "top-last-names.letter"
  }, {
    path: "/top-rated-last-names/state/",
    component: _cbcfeee4,
    name: "top-last-names.states"
  }, {
    path: "/top-rated-last-names/state/:state/",
    component: _abf90196,
    name: "top-last-names.state"
  }, {
    path: "/names/:letter/",
    component: _966c8e52,
    name: "bucket.letter"
  }, {
    path: "/names/:letter/:tier2/",
    component: _966c8e52,
    name: "bucket.tier2"
  }, {
    path: "/names/:letter/:tier2/:tier3/",
    component: _966c8e52,
    name: "bucket.tier3"
  }, {
    path: "/:name_address_phone_email_records/",
    component: _010a3790,
    name: "search.name"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
